import { useState, useEffect } from "react";
import PanelMenu from "../components/PanelMenu";
import axios from "axios";
import './css/Zoom.css'
import API_PATH from "../config/API_PATH";
import IMG_PATH from "../config/IMG_PATH";

import ConfirmModal from "../components/ConfirmModal";
import ZoomModal from "../components/ZoomModal";


function DisplayZoomlist(props){
    const [listItem, setListItem] = useState();
    
    const [isUpdate, setIsUpdate] = useState(false);
    const [disableAnim, setDisableAnim] = useState(false);

    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        msg: "no message",
        exec: ()=>{},
    });

    const [zoomCreateEdit, setZoomCreateEdit] = useState({
        modalOpen: false,
        mode: "create",
        titre: "",
        texte: "",
        id: "",
        src: "",
    })

    useEffect(()=>{
        renderList();
    },[]);

    useEffect(()=>{
        if(isUpdate && !disableAnim){
            setDisableAnim(true);
        }
        if(isUpdate && disableAnim){
            setIsUpdate(false);
        }
        if(!isUpdate && disableAnim){
            setDisableAnim(false);
        }
    },[isUpdate]);

    useEffect(()=>{
        if(listItem){
            renderList();
        }
    },[props.items]);

    function editModeToggle(item){
        setZoomCreateEdit({
            modalOpen: true,
            mode: "edit",
            titre: item.titre,
            texte: item.texte,
            id: item.id,
            src: item.src
        });
    }

    function createModeToggle(){
        setZoomCreateEdit({
            modalOpen: true,
            mode: "create",
            titre: "",
            texte: "",
            id: "",
            src: ""
        });
    }

    function deleteImg(src){
        const data = src.replace(IMG_PATH+"/", "");
        axios.post(API_PATH+"/media/deleteImg.php", {src: data})
        .then((res)=>console.log(res.data));
    }

    function toggleConfirmModal() {
        setConfirmModal({...confirmModal, isOpen: !confirmModal.isOpen});
    }

    function toggleEditModal() {
        setZoomCreateEdit({...zoomCreateEdit, modalOpen: !zoomCreateEdit.modalOpen})
    }

    function deleteZoom(item){
        const deleteMsg = `Êtes vous sûr de vouloir supprimer l'entrée pour "${item.titre}" ?`;
        const deleteExec = () => {
            deleteImg(item.src);
            axios.post(API_PATH+'/zoom/delete.php', {id: item.id})
            .then(()=>{
                props.refresh();
            }).catch((err)=>console.log(err));
        }

        setConfirmModal({
            isOpen: true,
            msg: deleteMsg,
            exec: ()=>deleteExec()
        })
    }

    function renderList(){
        if(props.items.length > 0){
            const listRender = props.items.map((zoom, index)=>{
                const elementId = "zoom"+zoom.id;
                const key = Math.round(Math.random() * 10000) + index;
                console.log(key);
                return(
                    <div 
                        style={{animationDelay: (40 * index + "ms") }}
                        className={"zoom-row " + (disableAnim ? " " : "play-zoom-anim")} 
                        key={key} 
                        id={elementId}>
                        <div className="zoom-img">
                            <img src={zoom.src} alt={"Zoom sur "+zoom.titre}/>
                        </div>
                        <div className="zoom-small-text">
                            {zoom.titre}
                        </div>
                        <div className="zoom-large-text">
                            {zoom.texte}
                        </div>
                        <div className="zoom-button-double">
                            <div 
                                onClick={()=>{
                                    setIsUpdate(true);
                                    editModeToggle(zoom);
                                }}
                                className="zoom-button" 
                                title="editer">&#9998;</div>
                            <div 
                                onClick={()=>{
                                    setIsUpdate(true);
                                    deleteZoom(zoom);
                                }}
                                className="zoom-button" 
                                title="supprimer">❌</div>
                        </div>
                    </div>
                    );
            });
            setListItem(listRender);
        }else{
            setListItem(()=>{
                return(
                <div>
                    
                </div>);
            });
        }
        
    }

    return(<>
        <ConfirmModal
             toggleBool={confirmModal.isOpen} 
             toggleFunc={()=>toggleConfirmModal()}
             msg={confirmModal.msg}
             func={()=>confirmModal.exec()}
             />
       {zoomCreateEdit.modalOpen && <ZoomModal 
            refresh={()=>{props.refresh();}} 
            modalToggleFunc={()=>toggleEditModal()}
            payload={zoomCreateEdit}
        />}
        <section id="zoom-tab">
            <div className="zoom-head zoom-row">
                <div className="zoom-img">Image</div>
                <div className="zoom-small-text">Titre du morceau</div>
                <div className="zoom-large-text">Texte</div>
                <div className="zoom-button-double">Fonctions</div>
            </div>
            <div className="zoom-items-container">
                {listItem}
            </div>
            <div className="new-entry-btn-cont">
                <div 
                    onClick={createModeToggle}
                    className="new-entry-button">
                        Nouveau
                </div>
            </div>
        </section>
        </>
    );

}

export default function Zoom(props){

    const [zoomList, setZoomlist] = useState([]);

    function getZoomlist(){
        axios.get(API_PATH+"/zoom/read.php?t="+Date.now())
        .then((res)=>{
            setZoomlist(res.data);
        });
    }

    function scrollBottom(){
        let div = document.getElementsByClassName('zoom-items-container')[0];
        div.scrollTop = div.scrollHeight;
    }

    // useEffect(()=>{
    //     scrollBottom();
    // }, [zoomList]);

    useEffect(()=>{
        getZoomlist();

        // setTimeout(()=>{
        //     scrollBottom();
        // }, 300);

        //scrollBottom();
        
    },[]);

    return(
        <>
            <PanelMenu login={()=>props.login()} />
            <main>
                <DisplayZoomlist
                    items={zoomList} 
                    refresh={()=>{getZoomlist();}}
                />
            </main>
        </>
    );
}