import { useState, useEffect, useRef } from "react";
import PanelMenu from "../components/PanelMenu";
import axios from "axios";
import './css/Setlist.css';
import API_PATH from "../config/API_PATH";

import sortSetList from "./func/sortSetList";
import SortButtons from "../components/SortButtons";
import ConfirmModal from "../components/ConfirmModal";

function FormAddSetlist(props){
    const[artiste, setArtiste] = useState('');
    const[titre, setTitre] = useState('');
    const[annee, setAnnee] = useState('');
    const[categorieABC, setCategorieABC] = useState('ABC');


    function sendData(e){
        e.preventDefault();

        if(artiste !== '' && titre !== '' && annee !== ''){
            const data = {artiste, titre, annee, categorieABC};

            axios.post(API_PATH+"/setlist/add.php", data)
            .then(()=>{
                setArtiste('');
                setTitre('');
                setAnnee('');
                setCategorieABC('ABC');
                props.refresh();
            });
        }
        else{
            if(artiste === ''){
                document.getElementById('form-artiste').classList.add('form-error');
            }
            if(titre === ''){
                document.getElementById('form-titre').classList.add('form-error');
            }
            if(annee === ''){
                document.getElementById('form-annee').classList.add('form-error');
            }
        }

    }

    return(
        <>
            <div>
                <input 
                    type="text" 
                    id="form-titre" 
                    placeholder="Titre" 
                    required
                    onChange={(e)=>setTitre(e.target.value)}
                    value={titre}
                    />
            </div>
            <div>
                <input 
                    type="text" 
                    id="form-artiste" 
                    placeholder="Artiste"
                    required
                    onChange={(e)=>setArtiste(e.target.value)} 
                    value={artiste}
                    />
            </div>
            <div>
                <input 
                    type="number" 
                    id="form-annee" 
                    placeholder="Année" 
                    required
                    min="1950" 
                    onChange={(e)=>setAnnee(e.target.value)}
                    value={annee}
                    />
            </div>
            <div>
                <select 
                    id="form-categorieABC"
                    onChange={(e)=>setCategorieABC(e.target.value)}
                    value={categorieABC}
                    >
                        <option value="ABC">ABC</option>
                        <option value="DEF">DEF</option>
                        <option value="GHI">GHI</option>
                        <option value="JKL">JKL</option>
                        <option value="MNO">MNO</option>
                        <option value="PQR">PQR</option>
                        <option value="STUV">STUV</option>
                        <option value="WXYZ">WXYZ</option>
                </select>
            </div>
            <div>
                <button onClick={sendData}>Ajouter</button>
            </div>
        </>
    );
}

function FormUpdateSetlist(props){
    const [updateId, setUpdateId] = useState(props.track.id);
    const [updateArtiste, setUpdateArtiste] = useState(props.track.artiste);
    const [updateTitre, setUpdateTitre] = useState(props.track.titre);
    const [updateAnnee, setUpdateAnnee] = useState(props.track.annee);
    const [updateCategorieABC, setUpdateCategorieABC] = useState(props.track.categorieABC);

    function updateTrack(e){
        e.preventDefault();
        const data = {
            id: updateId,
            artiste: updateArtiste,
            titre: updateTitre,
            annee: updateAnnee,
            categorieABC: updateCategorieABC
        };
        console.log(data);
        axios.post(API_PATH+"/setlist/update.php", data)
        .then(()=>{
            props.refresh();
            props.editToggle({});
        })
        .catch((err)=>console.log(err));
    }

    const cancel = () =>{
        props.editToggle({});
    }

    return (
        <>
            <div>
                <input 
                    type="text" 
                    id="form-update-titre" 
                    placeholder="Titre" 
                    required
                    onChange={(e)=>setUpdateTitre(e.target.value)}
                    value={updateTitre}
                    />
            </div>
            <div>
                <input 
                    type="text" 
                    id="form-update-artiste" 
                    placeholder="Artiste"
                    required
                    onChange={(e)=>setUpdateArtiste(e.target.value)} 
                    value={updateArtiste}
                    />
            </div>
           <div>
               <input 
                type="number" 
                id="form-update-annee" 
                placeholder="Annee" 
                required
                min="1950" 
                onChange={(e)=>setUpdateAnnee(e.target.value)}
                value={updateAnnee}
                />
            </div>
            <div>
                <select 
                    id="form-categorieABC"
                    onChange={(e)=>setUpdateCategorieABC(e.target.value)}
                    value={updateCategorieABC}
                    >
                        <option value="ABC">ABC</option>
                        <option value="DEF">DEF</option>
                        <option value="GHI">GHI</option>
                        <option value="JKL">JKL</option>
                        <option value="MNO">MNO</option>
                        <option value="PQR">PQR</option>
                        <option value="STUV">STUV</option>
                        <option value="WXYZ">WXYZ</option>
                </select>
            </div>
            <div className="double-button">
                <button className="tab-func" title="mettre à  jour" onClick={updateTrack}>✔</button>
                <button className="tab-func" title="cancel" onClick={()=>{cancel();}}>🔙</button>
            </div>
        </>
    );
}

function DisplaySetlist(props){
    const [isInit, setIsInit] = useState(true);
    const [ranOnce, setRanOnce] = useState(false);

    const [resetanim, setResetAnim] = useState(true);

    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        msg: "no message",
        exec: ()=>{},
    })

    const [list, setList] = useState([]); //list of tracks

    const [sortStatus, setSortStatus] = useState({
        col: "TITLE",
        dir: "ASC"
    });

    const [listItemsRender, setListItemsRender] = useState(); // full list render in JSX format

    const [editItem, setEditItem] = useState({});

    useEffect(()=>{ 
        setIsInit(true);
        setList(props.items);
     },[]);

    useEffect(()=>{
        if(list){
            renderList();
        }
    },[list]);

    useEffect(()=>{
        if(list){
            renderList();}
    },[editItem]);

    useEffect(()=>{
        if(ranOnce){
            setIsInit(false);
        }
    }, [ranOnce])

    useEffect(()=>{ 
        if(list){
            sortList(sortStatus.col, sortStatus.dir, isInit);

            setRanOnce(false);
        }
    },[props.items]);

    useEffect(()=>{
        if(list){
            renderList();
        }
    }, [sortStatus])

    function editModeToggle(item){
        setEditItem(item);
    }

    function toggleConfirmModal() {
        setConfirmModal({...confirmModal, isOpen: !confirmModal.isOpen});
    }

    function deleteTrack(track){
        const deleteMsg = `Êtes vous sûr de vouloir supprimer l'entrée pour "${track.titre}" ?`;
        const deleteExec = () => {
            const data = {id: track.id}
            console.log(data);
            axios.post(API_PATH+'/setlist/delete.php', data)
            .then(()=>{
                props.refresh();
            }).catch((err)=>console.log(err));
        }

        setConfirmModal({
            isOpen: true,
            msg: deleteMsg,
            exec: ()=>deleteExec()
        })
    }

    function sortList(col, dir, anim = true){
        console.log("sorting");
        const new_list = sortSetList(props.items, col.toUpperCase(), dir.toUpperCase());

        setList(new_list.list);

        setEditItem({});

        setResetAnim(anim);

        setSortStatus({
            col: new_list.sort,
            dir: new_list.dir,
        })
    }

    function renderList(){
        if(list.length > 0){
            const listRender = list.map((track, index)=>{
                const elementId = "track"+track.id;

                if(track.id != editItem.id){
                    return(
                        <div
                            style={{animationDelay: resetanim && index < 20 ? (40*index + "ms") : ""}}
                            className={`setlist-row 
                            ${resetanim ? " play-setlist-anim " : ""} 
                            ${sortStatus.dir === "ASC" ? " asc-anim" : " desc-anim"}`}
                            key={track.id+Math.random()} id={elementId}>
                                <div>{track.titre}</div>
                                <div>{track.artiste}</div>
                                <div>{track.annee}</div>
                                <div>{track.categorieABC}</div>
                                <div className="double-button">
                                    <button 
                                        onClick={()=>{editModeToggle(track)}}
                                        className="tab-func" 
                                        title="editer">&#9998;</button>
                                    <button 
                                        onClick={()=>{deleteTrack(track)}}
                                        className="tab-func" 
                                        title="supprimer">❌</button>
                                </div>
                        </div>
                    );
                }
                else{
                    return(
                        <div className="setlist-row" key={track.id}>
                            <FormUpdateSetlist refresh={()=>{
                                setIsInit(false);
                                props.refresh();
                            }} 
                            editToggle={setEditItem} track={track}/>
                        </div>
                    );
                }
            });
            if(resetanim){
                setResetAnim(false);
            }
            setListItemsRender(listRender);
        }
        else{
            setListItemsRender(()=>{
                return(
                <div>
                    
                </div>);
            }); 
        }
    }
    
    return (<>
        <ConfirmModal
             toggleBool={confirmModal.isOpen} 
             toggleFunc={()=>toggleConfirmModal()}
             msg={confirmModal.msg}
             func={()=>confirmModal.exec()}
             />
        <div id="setlist-tab">
            <div className="setlist-row header">
                <div>
                    <span className={sortStatus.col === "TITLE" ? "sort-highlight" : ""} >Titre</span>
                    <SortButtons col={"TITLE"} fnc={sortList} sortStatus={sortStatus}/>
                </div>
                <div>
                    <span className={ sortStatus.col === "ARTIST" ? "sort-highlight" : "" } >Artiste</span>
                    <SortButtons col={"ARTIST"} fnc={sortList} sortStatus={sortStatus}/>
                </div>
                <div>
                    <span className={ sortStatus.col === "YEAR" ? "sort-highlight" : "" } >Année</span>
                    <SortButtons col={"YEAR"} fnc={sortList} sortStatus={sortStatus}/>
                </div>
                <div>Catégorie ABC</div>
                <div>Fonctions</div>
            </div>
            <div className="setlist-main-content">
                {listItemsRender}
            </div>
            <div className="setlist-row add-form">
                <FormAddSetlist refresh={()=>{props.refresh();}} />
            </div>
        </div>
        </>
    );
}

export default function Setlist(props){

    const [setlist, setSetlist] = useState(null);

    function getSetlist(){
        axios.get(API_PATH+"/setlist/read.php?t="+Date.now())
        .then((res)=>{
            setSetlist(res.data);
        });
    }

    useEffect(()=>{getSetlist();},[]);

    return(
        <>
            <PanelMenu login={()=>props.login()}/> 
            <main id="setlist-main">
                {setlist &&
                    <DisplaySetlist 
                        items={setlist} 
                        refresh={()=>{getSetlist();}}
                        />  
                }
            </main>
        </>
    );
}