import { useState, useEffect } from "react";
import PanelMenu from "../components/PanelMenu";
import axios from "axios";
import API_PATH from "../config/API_PATH";
import IMG_PATH from "../config/IMG_PATH";
import "./css/BlockLayout.css";

import ConfirmModal from "../components/ConfirmModal";

function AddHome(props){
    const[selectedImage, setSelectedImage] = useState({});
    const[texte, setTexte] = useState('');
    const[url, setUrl] = useState('');

    function sendData(e){
        e.preventDefault();
        if(texte !== '' && typeof(selectedImage.size) !== undefined ){
            const src = IMG_PATH+"/home/"+selectedImage.name;
            uploadImg();
            const dataGallerie = {texte, src: src, url};
            axios.post(API_PATH+"/home/add.php", dataGallerie)
            .then((res)=>{
                setTexte('');
                setUrl('');
                props.refresh();
            });
        }else{
            if(texte == ''){
                document.getElementsByClassName('block-add-texte')[0].classList.add('form-error');
            }
        }
    }
    function handleImgChange(e){
        console.log(e.target.files[0]);
        setSelectedImage(e.target.files[0]);
    }
    function uploadImg(){
        const formData = new FormData();
        formData.append('subdir', 'home/');
        formData.append('file', selectedImage);

        axios.post(API_PATH+'/media/uploadImg.php', formData, {headers:{'content-type': 'multipart/form-data'}})
        .then((res)=>{
            console.log(res.data);
            setSelectedImage({});
            document.getElementById("form-img-input").value=null;
        })
        .catch(err=>console.log(err));
    }

    return(
        <div className="block-add-form">
            <div className="block-button-cont">
                <div className="block-button-title">
                    Ajouter
                </div>
                <div title="ajouter"
                    className="block-button block-button-right"
                    onClick={sendData}
                >
                    ➕
                </div>
            </div>
            <div className="block-item-cont">
                <input
                    className="block-add-img"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    id="form-img-input"
                    onChange={handleImgChange}
                />
                <input 
                    type="text"
                    className="block-add-texte"
                    placeholder="Titre"
                    value={texte}
                    onChange={(e) => setTexte(e.target.value)}
                />
                <input 
                    type="text"
                    className="block-add-texte"
                    placeholder="Lien"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
            </div>
        </div>
    );
}

function UpdateHome(props){
    const[updateId, setUpdateId] = useState(props.item.id);
    const[updateTexte, setUpdateTexte] = useState(props.item.texte);
    const[updateSelectedImage, setUpdateSelectedImage]=useState({});
    const[updateSrc, setUpdateSrc]=useState(props.item.src);
    const[updateUrl, setUpdateUrl]=useState(props.item.url);
    const currImg = props.item.src;

    function cancel(){
        props.editToggle({});
    }

    function updateData(){
        let data = {
            id: updateId,
            texte: updateTexte,
            url: updateUrl
        };
        if(typeof(updateSelectedImage.name) !== 'undefined'){
            props.deleteImg(currImg);
            uploadImg();
            const source=IMG_PATH+"/home/"+updateSelectedImage.name;
            data = {...data, src: source};
        }else{
            data = {...data, src: currImg};
        }
        console.log(data);
        axios.post(API_PATH+"/home/update.php", data)
        .then(()=>{
            props.refresh();
            props.editToggle({});
        }).catch((err)=>console.log(err));
    }
    
    function uploadImg(){
        const formData = new FormData();
        formData.append('subdir', 'home/');
        formData.append('file', updateSelectedImage);

        axios.post(API_PATH+'/media/uploadImg.php', formData, {headers:{'content-type': 'multipart/form-data'}})
        .then((res)=>{
            setUpdateSrc(IMG_PATH+"/home/"+updateSelectedImage.name);
            setUpdateSelectedImage({});
            document.getElementById("update-img-input").value=null;
            return true;
        })
        .catch(err=>console.log(err));

        return false;
    }

    function handleImgChange(e){
        setUpdateSelectedImage(e.target.files[0]);
    }

    function testUpdate(){
        console.log(updateSelectedImage.name);
    }

    return(
       <>
            <div className="block-button-cont">
                <div title="mettre à jour"
                    className="block-button block-button-left"
                    onClick={updateData}
                >
                    ✔
                </div>
                <div title="annuler"
                    className="block-button block-button-right"
                    onClick={cancel}
                >
                    🔙
                </div>
            </div>
            <div className="block-item-cont">
                <input
                    className="home-add-img"
                    type="file"
                    id="update-img-input"
                    onChange={handleImgChange}
                />
                <input
                    type="text"
                    className="block-add-text"
                    placeholder="Titre"
                    value={updateTexte}
                    onChange={(e) => setUpdateTexte(e.target.value)}
                />
                <input
                    type="text"
                    className="block-add-texte"
                    placeholder="URL"
                    value={updateUrl}
                    onChange={(e) => setUpdateUrl(e.target.value)}
                />
            </div>
        </>
    );
}

function DisplayHomepage(props){
    const [listItem, setListItem] = useState();
    const [editItem, setEditItem] = useState({});

    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        msg: "no message",
        exec: ()=>{},
    });

    useEffect(()=>{renderList();},[]);
    useEffect(()=>{renderList();},[props.items]);
    useEffect(()=>{renderList();},[editItem]);

    function toggleConfirmModal() {
        setConfirmModal({...confirmModal, isOpen: !confirmModal.isOpen});
    }

    function editModeToggle(item){
        setEditItem(item);
    }

    function deleteImg(src){
        const data = src.replace(IMG_PATH+"/", "");
        axios.post(API_PATH+"/media/deleteImg.php", {src: data})
        .then((res)=>console.log(res.data));
    }

    function deleteHomeItem(item){
        const deleteMsg = `Êtes vous sûr de vouloir supprimer l'entrée "${item.texte}" ?`;
        const deleteExec = () => {
            deleteImg(item.src);
            axios.post(API_PATH+'/home/delete.php', {id: item.id})
            .then(()=>{
                props.refresh();
            }).catch((err)=>console.log(err));
        }

        setConfirmModal({
            isOpen: true,
            msg: deleteMsg,
            exec: ()=>deleteExec()
        })
    }


    function renderList(){
        if (props.items.length > 0) {
            const listRender = props.items.map((homeItem, index) => {
                if (homeItem.id != editItem.id) {
                    return (
                        <div 
                            style={{animationDelay: (40 * index + "ms") }}
                            className="block-item" key={homeItem.id}>
                            <div className="block-button-cont">
                                <div title="editer"
                                    className="block-button block-button-left"
                                    onClick={() => { editModeToggle(homeItem) }}>
                                    <span>&#9998;</span>
                                </div>
                                <div title="supprimer"
                                    className="block-button block-button-right"
                                    onClick={() => { deleteHomeItem(homeItem) }}>
                                    <span>❌</span>
                                </div>
                            </div>
                            <div className="block-item-cont">
                                <img src={homeItem.src} />
                                <div className="block-legende">
                                    {homeItem.texte}
                                </div>
                                {homeItem.url!='' && <a className="block-url" href={homeItem.url} target="_blank">
                                    {homeItem.url}
                                </a>}
                            </div>
                        </div>
                    );
                }else{
                    return(
                        <div className="block-edit-form" key={homeItem.id}>
                        <UpdateHome 
                            deleteImg={deleteImg}
                            refresh={()=>{props.refresh();}}
                            editToggle={setEditItem}
                            item={homeItem}
                        />
                    </div>
                    );
                }
            });
            setListItem(listRender);
        }else{
            setListItem(()=>{
                return(
                <>
                    
                </>);
            });
        }
    }

    return(<>
        <ConfirmModal
             toggleBool={confirmModal.isOpen} 
             toggleFunc={()=>toggleConfirmModal()}
             msg={confirmModal.msg}
             func={()=>confirmModal.exec()}
             />
        <section className="block-list">
            <AddHome refresh={()=>{props.refresh();}} />
            {listItem}
        </section>
        </>
    );
}

export default function Home(props){
    const[homepage, setHomepage] = useState([]);

    function getHomepage(){
        axios.get(API_PATH+"/home/read.php?t="+Date.now())
        .then((res)=>{
            setHomepage(res.data);
        }).catch((err)=>console.log(err));
    }

    useEffect(()=>{getHomepage();}, []);

    return(
        <>
            <PanelMenu login={()=>props.login()} />
            <main className="block-main">
                <DisplayHomepage
                    items={homepage} 
                    refresh={()=>{getHomepage();}}
                    />  
            </main>
        </>
    );
}