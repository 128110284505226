import axios, { Axios } from "axios";
import React from "react";
import { useState, useEffect } from "react";
import API_PATH from "./config/API_PATH";
import LoginForm from "./components/LoginForm"
import { Route, Routes, BrowserRouter as Router, Navigate } from "react-router-dom";

/*PAGES DE NOTRE BACKEND*/
import Setlist from "./pages/Setlist";
import Extraits from "./pages/Extraits";
import Home from "./pages/Home";
import Zoom from "./pages/Zoom";
import Gallerie from "./pages/Gallerie";



function App() {
  
    //const axios = require('axios');
    const[loggedIn, setLoggedIn] = useState(false);


    function checkLogin(){
        axios.get(API_PATH+"/checkStatus.php", {withCredentials: true})
        .then((res)=>{
            setLoggedIn(res.data.logged_in);
            //console.log(res.data);
        }).catch((err)=>{
            console.log("err status : "+err);
        });

    }

    useEffect(()=>{checkLogin();}, []);


  return (
    <Router>
      <Routes>
        <Route exact path="/" element={
          <>
            {!loggedIn && <LoginForm login={()=>checkLogin()} />}
            {loggedIn && <Navigate replace to="/accueil" />}
          </>
        } />
        {loggedIn && <Route exact path="/accueil" element={<Home login={()=>checkLogin()} />}/>}
        {loggedIn && <Route exact path="/extraits" element={<Extraits login={()=>checkLogin()} />}/>}
        {loggedIn && <Route exact path="/gallerie" element={<Gallerie login={()=>checkLogin()} />}/>}
        {loggedIn && <Route exact path="/setlist" element={<Setlist login={()=>checkLogin()} />}/>}
        {loggedIn && <Route exact path="/zoom" element={<Zoom login={()=>checkLogin()} />}/>}
        <Route path="*" element={<Navigate to="/"/>}/>
      </Routes>
    </Router>
  );
}

export default App;
