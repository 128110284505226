import { useState, useEffect } from "react";
import './css/SortButtons.css';

// col will refer to the sorting column as defined in sortSetList.js
// fnc will be the sorting function
// sorStatus contains which column and direction should be highlighted

const SortButtons = ({ col, fnc, sortStatus }) =>{

    const [highlight, setHighlight] = useState("NONE");

    useEffect(()=>{
        if(sortStatus.col !== col.toUpperCase()) {
            setHighlight("NONE");
            return;
        }

        setHighlight(sortStatus.dir);

    }, [sortStatus]);

    return (
    <search className="sort-buttons">
        <div
            className={
                "sort-chevron chevron-asc" + (highlight === "ASC" ? " chevron-active" : "")
            }
            onClick={()=>fnc(col, "ASC")}>  
        </div>
        <div
            className={
                "sort-chevron chevron-desc" + (highlight === "DESC" ? " chevron-active" : "")
            }
            onClick={()=>fnc(col, "DESC")}>  
        </div> 
    </search>
    );
}

export default SortButtons;