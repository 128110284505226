import { useState, useEffect } from "react";
import PanelMenu from "../components/PanelMenu";
import axios from "axios";
import API_PATH from "../config/API_PATH";
import AUDIO_PATH from "../config/AUDIO_PATH";
import './css/BlockLayout.css';
import ConfirmModal from "../components/ConfirmModal";

function AddExtrait(props){
    const [type, setType] = useState('audio');
    const [titreDB, setTitreDB] = useState('');
    const [titreDisplay, setTitreDisplay] = useState('');
    const [url, setURL] = useState('');
    const [selectedFile, setSelectedFile] = useState({});

    function sendData(e){
        e.preventDefault();
        const src = (type === 'audio') ? AUDIO_PATH+"/"+selectedFile.name : url;
        let isOK = true;

        if(titreDB !== '' && titreDisplay !== '' && type !== '' && src !== ''){
            
            if(type === 'audio' && selectedFile.name.endsWith('.mp3')){
                uploadFile();
            }else{
                isOK = (type === 'video') ? true : false;
            }

            if(isOK){
                const data = {titreDB, titreDisplay, type, src};
                axios.post(API_PATH+"/extraits/add.php", data)
                .then((res)=>{
                    setURL('');
                    setTitreDB('');
                    setTitreDisplay('');
                    setType('audio');
                    props.refresh();
                });
            }else{
                alert('Mauvais type de fichier');
            }
        }else{
            if(titreDB === ''){
               document.getElementById("extrait-add-titreDB").classList.add('form-error');
            }
            if(titreDisplay === ''){
                document.getElementById("extrait-add-titreDisplay").classList.add('form-error');
             }
            if(type === 'video' && url === ''){
                document.getElementById("extrait-add-url").classList.add('form-error');
            }
        }
    
    }

    function uploadFile(){
        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post(API_PATH+"/media/uploadAudio.php", formData, {headers:{'content-type': 'multipart/form-data'}})
        .then((res)=>{
            console.log(res.data);
            setSelectedFile({});
            document.getElementById('extrait-add-file').value=null;
        })
        .catch(err=>console.log(err));
    }

    function handleFileChange(e){
        console.log(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
    }

    return(
        <div className="block-add-form">
            <div className="block-button-cont">
                <div className="block-button-title">
                    Ajouter
                </div>
                <div
                    className="block-button block-button-right"
                    title="ajouter"
                    onClick={sendData}>
                    <span>➕</span>
                </div>
            </div>
            <div className="block-item-cont">
                <select
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                >
                    <option value="audio">Audio</option>
                    <option value="video">Video</option>
                </select>
                {type === 'audio' &&
                    <input
                        type="file"
                        accept=".mp3"
                        id="extrait-add-file"
                        onChange={handleFileChange}
                    />
                }
                {type === 'video' &&
                    <input
                        type="text"
                        placeholder="URL youtube"
                        id="extrait-add-url"
                        onChange={(e) => setURL(e.target.value)}
                        value={url}
                    />
                }
                <input
                    type="text"
                    placeholder="Titre du morceau"
                    id="extrait-add-titreDB"
                    onChange={(e) => setTitreDB(e.target.value)}
                    value={titreDB}
                />
                <input
                    type="text"
                    placeholder="Description de l'extrait"
                    id="extrait-add-titreDisplay"
                    onChange={(e) => setTitreDisplay(e.target.value)}
                    value={titreDisplay}
                />
            </div>
        </div>
    );
}

function EditExtrait(props){
    const [updateId, setUpdateId] = useState(props.item.id);
    const [updateTitreDB, setUpdateTitreDB] = useState(props.item.titre_db);
    const [updateTitreDisplay, setUpdateTitreDisplay] = useState(props.item.titre_display);
    const [updateSelectedFile, setUpdateSelectedFile] = useState({});
    const [updateSrc, setUpdateSrc] = useState(props.item.src);
    const [updateURL, setUpdateURL] = useState(props.item.src);
    
    const currType = props.item.type;
    const currFile = props.item.src;

    function updateData(){
        let data = {
            id: updateId,
            titreDB: updateTitreDB,
            titreDisplay: updateTitreDisplay,
            type: currType
        };
        if(currType==='audio' && typeof(updateSelectedFile.name)!==undefined){
            props.deleteFile(currFile);
            uploadFile();
            setUpdateSrc(AUDIO_PATH+"/"+updateSelectedFile.name);
        }else{
            setUpdateSrc(updateURL);
        }
        data = {...data, src: updateSrc};
        axios.post(API_PATH+"/extraits/update.php", data)
        .then(()=>{
            props.refresh();
            props.editToggle({});
        }).catch((err)=>console.log(err));
    }

    function uploadFile(){
        const formData = new FormData();
        formData.append('file', updateSelectedFile);

        axios.post(API_PATH+'/media/uploadAudio.php', formData, {headers:{'content-type': 'multipart/form-data'}})
        .then((res)=>{
            setUpdateSrc(AUDIO_PATH+"/"+updateSelectedFile.name);
            setUpdateSelectedFile({});
            document.getElementById("extrait-update-file").value=null;
            return true;
        })
        .catch(err=>console.log(err));

        return false;
    }

    function cancel(){
        props.editToggle({});
    }
    function handleFileChange(e){
        setUpdateSelectedFile(e.target.files[0]);
    }


    return(
        <>
            <div className="block-button-cont">
                <div title="mettre à jour"
                    className="block-button block-button-left"
                    onClick={updateData}
                >
                    <span>✔</span>
                </div>
                <div title="annuler"
                    className="block-button block-button-right"
                    onClick={cancel}
                >
                    <span>🔙</span>
                </div>
            </div>
            <div className="block-item-cont">
                {currType === 'audio' &&
                    <input
                        type="file"
                        id="extrait-update-file"
                        onChange={handleFileChange}
                    />
                }
                {currType === 'video' &&
                    <input
                        type="text"
                        placeholder="URL youtube"
                        id="extrait-update-url"
                        onChange={(e) => setUpdateURL(e.target.value)}
                        value={updateURL}
                    />
                }
                <input
                    type="text"
                    placeholder="Titre du morceau"
                    id="extrait-update-titreDB"
                    onChange={(e) => setUpdateTitreDB(e.target.value)}
                    value={updateTitreDB}
                />
                <input
                    type="text"
                    placeholder="Description de l'extrait"
                    id="extrait-update-titreDisplay"
                    onChange={(e) => setUpdateTitreDisplay(e.target.value)}
                    value={updateTitreDisplay}
                />
            </div>
        </>
    );
}

function DisplayExtraits(props){
    const [listItem, setListItem] = useState();
    const [editItem, setEditItem] = useState({});

    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        msg: "no message",
        exec: ()=>{},
    });

    function toggleConfirmModal() {
        setConfirmModal({...confirmModal, isOpen: !confirmModal.isOpen});
    }

    useEffect(()=>{renderList();},[]);
    useEffect(()=>{renderList();},[props.items]);
    useEffect(()=>{renderList();},[editItem]);

    function editModeToggle(item){
        setEditItem(item);
    }

    function deleteFile(src){
        const data = src.replace(AUDIO_PATH+"/", "");
        axios.post(API_PATH+"/media/deleteAudio.php", {src: data})
        .then((res)=>console.log(res.data));
    }

    function deleteExtrait(item){
        const deleteMsg = `Êtes vous sûr de vouloir supprimer l'extrait pour "${item.titre_db}" ?`;
        const deleteExec = () => {
            if(item.type === 'audio'){
                deleteFile(item.src);
            }
            axios.post(API_PATH+'/extraits/delete.php', {id: item.id})
            .then(()=>{
                props.refresh();
            }).catch((err)=>console.log(err));
        }

        setConfirmModal({
            isOpen: true,
            msg: deleteMsg,
            exec: ()=>deleteExec()
        })
    }

    function renderList(){
        if(props.items.length > 0){
            const listRender = props.items.map((extrait, index)=>{
                let embedUrl = '';
                if(extrait.id != editItem.id){
                    if(extrait.type === 'video'){
                        embedUrl = extrait.src.replace("https://www.youtube.com/watch?v=", "")
                    }
                    return(
                        <div 
                            style={{ animationDelay: index < 20 ? (40*index + "ms") : "" }} 
                            className="block-item" key={extrait.id}>
                            <div className="block-button-cont">
                                <div title="editer"
                                    className="block-button block-button-left"
                                    onClick={() => { editModeToggle(extrait) }}>
                                    <span>&#9998;</span>
                                </div>
                                <div title="supprimer"
                                    className="block-button block-button-right"
                                    onClick={() => { deleteExtrait(extrait) }}>
                                    <span>❌</span>
                                </div>
                            </div>
                            <div className="block-item-cont">
                                {extrait.type === 'audio' &&
                                    <div className="block-audio">
                                        <h4>{extrait.src.replace(AUDIO_PATH + "/", "")}</h4>
                                        <audio controls>
                                            <source src={extrait.src} type="audio/mpeg" />
                                        </audio>
                                    </div>}
                                {extrait.type === 'video' &&
                                    <iframe
                                        src={"https://www.youtube.com/embed/" + embedUrl}
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>}
                                <div className="block-texte">
                                    {extrait.titre_db}
                                </div>
                                <div className="block-texte">
                                    {extrait.titre_display}
                                </div>
                            </div>
                        </div>
                    );
                }else{
                    return(
                        <div className="block-edit-form" key={extrait.id}>
                            <EditExtrait
                                deleteFile={deleteFile}
                                refresh={()=>{props.refresh();}}
                                editToggle={setEditItem}
                                item={extrait}
                            />
                        </div>
                    );
                }
            });
            setListItem(listRender);
        }else{
            setListItem(()=>{return(<></>);});
        }
    }

    return(<>
        <ConfirmModal
             toggleBool={confirmModal.isOpen} 
             toggleFunc={()=>toggleConfirmModal()}
             msg={confirmModal.msg}
             func={()=>confirmModal.exec()}
             />
        <section className="block-list">
            <AddExtrait refresh={()=>{props.refresh();}} />
            {listItem}
        </section>
    </>
    );

}


export default function Extraits(props){
    const[extraits, setExtraits]=useState([]);

    function getExtraits(){
        axios.get(API_PATH+"/extraits/read.php?t="+Date.now())
        .then((res)=>{
            setExtraits(res.data);
        }).catch((err)=>console.log(err));
    }

    useEffect(()=>{getExtraits();}, []);

    return(
        <>
            <PanelMenu login={()=>props.login()} />
            <main className="block-main">
            <DisplayExtraits 
                items={extraits}
                refresh={()=>{getExtraits();}} 
                />
            </main>
        </>
    );
}