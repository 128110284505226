import { useState, useRef } from "react";

import axios from "axios";

import API_PATH from "../config/API_PATH";
import IMG_PATH from "../config/IMG_PATH";

import './css/ZoomModal.css';

const ZoomModal = ({modalToggleFunc, payload, refresh}) => { 
    const [titre, setTitre] = useState(payload.titre);
    const [texte, setTexte] = useState(payload.texte);
    const [src, setSrc] = useState(payload.src);

    const[selectedImgPreview, setSelectedImgPreview] = useState(payload.src);

    const[selectedImage, setSelectedImage] = useState({});

    const txtRef = useRef(null);
    const titreRef = useRef(null);
    const imgInput = useRef(null);

    function sendData(){

        if(payload.mode === "create" && typeof(selectedImage.size) === 'undefined' ){
            console.log("no image for new entry");
            return false;
        }

        if(titre !== '' && texte !== ''){

            let zoomSrc = src;

            if( typeof(selectedImage.name) !== 'undefined' && typeof(selectedImage.size) !== 'undefined' ){
                zoomSrc = IMG_PATH+'/zoom/'+selectedImage.name;

                if(zoomSrc !== src ){
                    console.log({src, zoomSrc})

                    if(src !== ""){
                        deleteImg(src);
                    }
                    uploadImg();
                }
            }
            
            const dataZoom = {titre, texte, src: zoomSrc, id: payload.id};

            axios.post( 
                API_PATH + (payload.mode === "edit" ? "/zoom/update.php" : "/zoom/add.php")
                , dataZoom)
            .then(()=>{
                console.log("data sent");
                refresh();
                modalToggleFunc();
                return true;
            }).catch((err)=>console.log(err));
        }
        else{
            console.log("missing info");
            if(titre == ''){
                titreRef.current.classList.add('form-error');
            }
            if(texte == ''){
                txtRef.current.classList.add('form-error'); 
            }

            return false;
        }
    }

    function deleteImg(src){
        const data = src.replace(IMG_PATH+"/", "");
        axios.post(API_PATH+"/media/deleteImg.php", {src: data})
        .then((res)=>console.log(res.data));
    }

    function uploadImg(){
        const formData = new FormData();
        formData.append('subdir', 'zoom/');
        formData.append('file', selectedImage);

        axios.post(API_PATH+'/media/uploadImg.php', formData, {headers:{'content-type': 'multipart/form-data'}})
        .then((res)=>{
            console.log(res.data);
            setSrc(IMG_PATH+"/zoom/"+selectedImage.name);
            setSelectedImage({});
            return true;
        })
        .catch(err=>console.log(err));

        return false;
    }

    function handleImgChange(e){
        // console.log(e.target.files[0]);
        setSelectedImage(e.target.files[0]);
        const fileReader = new FileReader();
        fileReader.onloadend = (e) =>{
            setSelectedImgPreview(e.target.result);
        }
        fileReader.readAsDataURL(e.target.files[0]);
    }

    function resetImgSelect(){
        setSelectedImage({});
        imgInput.current.value=null;
        setSelectedImgPreview(payload.src);
    }

    return (
    <div 
        className="modal-cont"
        onClick={(e)=>{
            e.stopPropagation();
            modalToggleFunc();
        }}>
        <div 
            className="main-modal  zoom-modal"
            onClick={(e)=>e.stopPropagation()}>
                <div className="zoom-modal-img">
                    <div className="zoom-preview-cont">
                        {selectedImgPreview !== "" && 
                            <img className="zoom-preview-img" src={selectedImgPreview} /> 
                        }
                    </div>
                    <div className="zoom-img-input">
                        <input 
                            id="edit-img-input"
                            type="file" 
                            accept=".jpg, .jpeg, .png"
                            ref={imgInput}
                            onChange={handleImgChange}
                            />
                        {typeof(selectedImage.name) !== 'undefined' && 
                            <button onClick={resetImgSelect}>
                                Reset
                            </button>
                        }
                    </div>
                </div>
                <div className="zoom-modal-text">
                        <input 
                            className="zoom-modal-title"
                            type="text"
                            value={titre}
                            ref={titreRef}
                            placeholder="Titre du morceau"
                            onChange={(e)=>setTitre(e.target.value)}
                        />
                        <textarea
                            className="zoom-modal-textarea"
                            value={texte}
                            ref={txtRef}
                            placeholder="Texte du zoom"
                            onChange={(e)=>setTexte(e.target.value)}
                        ></textarea>
                    <div className="zoom-modal-button-double">
                        <div 
                            onClick={(e)=>{
                                e.stopPropagation();
                                sendData();     
                            }}
                            className="zoom-modal-button zoom-modal-ok" 
                            title="mettre à jour">
                            {payload.mode === "edit" ? "Mettre à jour" : "Créer"}
                        </div>
                        <div 
                            onClick={(e)=>{
                                e.stopPropagation();
                                modalToggleFunc();
                            }}
                            className="zoom-modal-button zoom-modal-cancel" 
                            title="annuler">
                                Annuler
                        </div>
                    </div>
            </div>
        </div>
    </div>
    );
}

export default ZoomModal;