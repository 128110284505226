import { useState } from "react";
import API_PATH from '../config/API_PATH';
import axios, { Axios } from "axios";

export default function LoginForm(props){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const[error, setError]=useState(false);

    function handleSubmit(e){
        e.preventDefault();
  
          const data = {
              username: username,
              password: password,
              login: "submit"
          }
  
        axios.post(API_PATH+"/login.php", data, {withCredentials: true})
        .then((res)=>{
            //console.log(res.data);
            if(res.data.hasOwnProperty('err')){
                setError(res.data.err);
                setPassword('');
                setUsername('');
            }else{
                setError(false);
            }
            props.login();
        }).catch((err)=>{
            console.log("error" + err);
        });
     
      }


    return(
        <main id="login-main">
            <form id="login-form" onSubmit={handleSubmit}>
                {error && <span className="error-login-form">{error}</span>}
                <label htmlFor="username">Nom de compte</label>
                <input
                    type="text"
                    id="username"
                    autoComplete="off"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                />
                <label htmlFor="password">Mot de passe</label>
                <input
                    type="password"
                    id="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <button>Connexion</button>
            </form>
        </main>
    );
}