import './css/ConfirmModal.css';

// toggleBool: boolean => from outside the component that indicates whether the modal is open or not
// toggleFunc: function => toggles toggleBool on/off
// msg: string => confirmation msg
// func: function => to execute on confirm

const ConfirmModal = ({toggleBool, toggleFunc, msg, func}) =>{
    return(
    <div 
        className="confirm-modal-cont modal-cont"
        onClick={(e)=>{
            e.stopPropagation();
            toggleFunc();
        }}
        style={{
            display: toggleBool ? "flex" : "none"
        }}>
            <div onClick={(e)=>{
                        e.stopPropagation();
                }}
                className="confirm-modal main-modal">
                <h3>{msg}</h3>
                <div className="confirm-buttons">
                    <div 
                        className='confirm-button modal-confirm-btn'
                        onClick={(e)=>{
                        e.stopPropagation();
                        func();
                        toggleFunc();
                    }}>
                        Supprimer
                    </div>
                    <div 
                        className='confirm-button modal-cancel-btn'
                        onClick={(e)=>{
                        e.stopPropagation();
                        toggleFunc();
                    }}>
                        Annuler
                    </div>
                </div>
            </div>
    </div>);
}

export default ConfirmModal;