import axios from "axios";
import API_PATH from "../config/API_PATH";
import { useNavigate } from "react-router-dom";

export default function Disconnect(props){
    let nav = useNavigate();

    function redirect(){
        nav('/', {replace: true});
    }

    function logout(){
    
        axios.post(API_PATH+"/logout.php",{
            logout: 'submit',
        },{
            withCredentials: true,
        }).then((res)=>{
           props.login();
        })
        .catch((err)=>{
            console.log("logout err : "+err);
        })

        
    }

    return(
        <button id="logout-button" onClick={()=>{
            logout();
            redirect();
        }
        }>Déconnexion</button>
    );
}