import { useState, useEffect } from "react";
import PanelMenu from "../components/PanelMenu";
import axios from "axios";
import API_PATH from "../config/API_PATH";
import IMG_PATH from "../config/IMG_PATH";
import './css/BlockLayout.css';

import ConfirmModal from "../components/ConfirmModal";

function AddGallerie(props){
    const[selectedImage, setSelectedImage] = useState({});
    const[legende, setLegende] = useState('');

    function sendData(e){
        e.preventDefault();
        if(legende !== '' && typeof(selectedImage.size) !== undefined ){
            const src = IMG_PATH+"/gallerie/"+selectedImage.name;
            uploadImg();
            const dataGallerie = {legende, src: src};
            axios.post(API_PATH+"/gallerie/add.php", dataGallerie)
            .then((res)=>{
                setLegende('');
                props.refresh();
            });
        }else{
            if(legende == ''){
                document.getElementById('block-add-item').getElementsByTagName('textarea')[0].classList.add('form-error');
            }
        }
    }

    function handleImgChange(e){
        console.log(e.target.files[0]);
        setSelectedImage(e.target.files[0]);
    }

    function uploadImg(){
        const formData = new FormData();
        formData.append('subdir', 'gallerie/');
        formData.append('file', selectedImage);

        axios.post(API_PATH+'/media/uploadImg.php', formData, {headers:{'content-type': 'multipart/form-data'}})
        .then((res)=>{
            console.log(res.data);
            setSelectedImage({});
            document.getElementById("form-img-input").value=null;
        })
        .catch(err=>console.log(err));
    }

    return(
        <div className="block-add-form">
            <div className="block-button-cont">
                <div className="block-button-title">
                    Ajouter
                </div>
                <div title="ajouter"
                    className="block-button block-button-right"
                    onClick={sendData}
                >
                    ➕
                </div>
            </div>
            <div className="block-item-cont">
                <input
                    className="block-add-img"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    id="form-img-input"
                    onChange={handleImgChange}
                />
                <textarea
                    className="block-add-textarea"
                    placeholder="legende"
                    value={legende}
                    onChange={(e) => setLegende(e.target.value)}
                ></textarea>
            </div>
        </div>
    );

}

function EditGallerie(props){
    const[updateId, setUpdateId] = useState(props.item.id);
    const[updateLegende, setUpdateLegende] = useState(props.item.legende);
    const[updateSelectedImage, setUpdateSelectedImage]=useState({});
    const[updateSrc, setUpdateSrc]=useState(props.item.src);
    const currImg = props.item.src;

    function cancel(){
        props.editToggle({});
    }

    function updateData(){
        let data = {
            id: updateId,
            legende: updateLegende
        };
        if(typeof(updateSelectedImage.name) !== 'undefined'){
            props.deleteImg(currImg);
            uploadImg();
            const source=IMG_PATH+"/gallerie/"+updateSelectedImage.name;
            data = {...data, src: source};
        }else{
            data = {...data, src: currImg};
        }

        console.log(data);
        axios.post(API_PATH+"/gallerie/update.php", data)
        .then(()=>{
            props.refresh();
            props.editToggle({});
        }).catch((err)=>console.log(err));
    }

    function uploadImg(){
        const formData = new FormData();
        formData.append('subdir', 'gallerie/');
        formData.append('file', updateSelectedImage);

        axios.post(API_PATH+'/media/uploadImg.php', formData, {headers:{'content-type': 'multipart/form-data'}})
        .then((res)=>{
            setUpdateSrc(IMG_PATH+"/gallerie/"+updateSelectedImage.name);
            setUpdateSelectedImage({});
            document.getElementById("update-img-input").value=null;
            return true;
        })
        .catch(err=>console.log(err));

        return false;
    }

    function handleImgChange(e){
        setUpdateSelectedImage(e.target.files[0]);
    }

    return(
        <>
            <div className="block-button-cont">
                <div title="mettre à jour"
                    className="block-button block-button-left"
                    onClick={updateData}
                >
                    ✔
                </div>
                <div title="annuler"
                    className="block-button block-button-right"
                    onClick={cancel}
                >
                    🔙
                </div>
            </div>
            <div className="block-item-cont">
                <input
                    className="block-add-img"
                    type="file"
                    id="update-img-input"
                    onChange={handleImgChange}
                />
                <textarea
                    className="block-add-textearea"
                    placeholder="legende"
                    value={updateLegende}
                    onChange={(e) => setUpdateLegende(e.target.value)}
                ></textarea>
            </div>
        </>
    );
}

function DisplayGallerie(props){
    const [listItem, setListItem] = useState();
    const [editItem, setEditItem] = useState({});

    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        msg: "no message",
        exec: ()=>{},
    });

    function toggleConfirmModal() {
        setConfirmModal({...confirmModal, isOpen: !confirmModal.isOpen});
    }

    useEffect(()=>{renderList();},[]);
    useEffect(()=>{renderList();},[props.items]);
    useEffect(()=>{renderList();},[editItem]);

    function editModeToggle(item){
        setEditItem(item);
    }

    function deleteImg(src){
        const data = src.replace(IMG_PATH+"/", "");
        axios.post(API_PATH+"/media/deleteImg.php", {src: data})
        .then((res)=>console.log(res.data));
    }

    function deleteGallerie(item){
        const deleteMsg = `Êtes vous sûr de vouloir supprimer l'entrée "${item.legende}" ?`;
        const deleteExec = () => {
            if(item.type === 'audio'){
                deleteImg(item.src);
            }
            axios.post(API_PATH+'/gallerie/delete.php', {id: item.id})
            .then(()=>{
                props.refresh();
            }).catch((err)=>console.log(err));
        }

        setConfirmModal({
            isOpen: true,
            msg: deleteMsg,
            exec: ()=>deleteExec()
        })
    }

    function renderList(){
        if(props.items.length > 0){
            const listRender = props.items.map((gallerieItem, index)=>{
                if(gallerieItem.id != editItem.id){
                    return(
                        <div
                            style={{ animationDelay: index < 20 ? (40*index + "ms") : "" }} 
                            className="block-item" key={gallerieItem.id}>
                            <div className="block-button-cont">
                                
                                <div title="editer"
                                    className="block-button block-button-left"
                                    onClick={() => { editModeToggle(gallerieItem) }}>
                                    <span>&#9998;</span>
                                </div>
                                <div title="supprimer"
                                    className="block-button block-button-right"
                                    onClick={() => { deleteGallerie(gallerieItem) }}>
                                    <span>❌</span>
                                </div>
                            </div>
                            <div className="block-item-cont">
                                <img src={gallerieItem.src} />
                                <div className="block-legende">
                                    {gallerieItem.legende}
                                </div>
                            </div>
                        </div>
                    );
                }else{
                    return(
                        <div className="block-edit-form" key={gallerieItem.id}>
                            <EditGallerie 
                                deleteImg={deleteImg}
                                refresh={()=>{props.refresh();}}
                                editToggle={setEditItem}
                                item={gallerieItem}
                            />
                        </div>
                    );
                }
            });
            setListItem(listRender);
        }else{
            setListItem(()=>{
                return(
                <>
                    
                </>);
            });
        }
    }

    return(<>
        <ConfirmModal
             toggleBool={confirmModal.isOpen} 
             toggleFunc={()=>toggleConfirmModal()}
             msg={confirmModal.msg}
             func={()=>confirmModal.exec()}
             />
        <section className="block-list">
            <AddGallerie refresh={()=>{props.refresh();}}/>
            {listItem}
        </section>
        </>
    );
}

export default function Gallerie(props){
    const[gallerie, setGallerie] = useState([]);

    function getGallerie(){
        axios.get(API_PATH+"/gallerie/read.php?t="+Date.now())
        .then((res)=>{
            setGallerie(res.data);
        }).catch((err)=>console.log(err));
    }

    useEffect(()=>{getGallerie();}, []);

    return(
        <>
            <PanelMenu login={()=>props.login()} />
            <main className="block-main">
                <DisplayGallerie 
                    items={gallerie} 
                    refresh={()=>{getGallerie();}}
                    />  
            </main>
        </>
    );
}