const SORTING_TYPE = [
    "ARTIST",
    "TITLE",
    "YEAR"
];

const DEFAULT_SORT = "ARTIST";

export default function sortSetList(list, sort_type, dir = "ASC"){

    const sort_dir = dir.toUpperCase() === "DESC" ? "DESC" : "ASC";
    const dir_mod = sort_dir === "DESC" ? -1 : 1;

    const sorted_list = list;

    if( !SORTING_TYPE.includes( sort_type.toUpperCase() ) ) {
        return {
            list: sorted_list,
            sort: DEFAULT_SORT,
            dir: sort_dir
        };
    }

    switch( sort_type.toUpperCase() ){
        case "ARTIST":
            sorted_list.sort((a, b)=>{
                const artist_a = a.artiste.toUpperCase();
                const artist_b = b.artiste.toUpperCase();

                if (artist_a === artist_b) return 0;

                return dir_mod * ( artist_a > artist_b ? 1 : -1 );

            })
            break;
        case "TITLE":
            sorted_list.sort((a, b)=>{
                const title_a = a.titre.toUpperCase();
                const title_b = b.titre.toUpperCase();

                if (title_a === title_b) return 0;

                return dir_mod * ( title_a > title_b ? 1 : -1 );

            })
            break;
        case "YEAR":
            sorted_list.sort((a, b)=>{
                const year_a = a.annee;
                const year_b = b.annee;

                if (year_a === year_b) return 0;

                return dir_mod * ( year_a > year_b ? 1 : -1 );
            })
            break;
    }
   

    return {
        list: sorted_list,
        sort: sort_type.toUpperCase(),
        dir: sort_dir
    };
}